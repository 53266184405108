import React from 'react'
import SearchPage from './SearchPage';

function Exams() {
    return (
        <div>
            <SearchPage />
        </div>
    )
}

export default Exams
